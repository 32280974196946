import axios from 'axios';

export const get_login = (data) => ({
    type: 'GET_LOGIN',
    data : data
})

export const toggle_loader = (data) => ({
    type: 'TOGGLE_LOADER',
    data : data
})

export const localize = (data) => ({
    type: 'LOCALIZE',
    data
})

export const country_list = (data) => ({
    type: 'COUNTRY_LIST',
    data
})

export const login = () => {
    return dispatch => {
        dispatch(toggle_loader(true))
        axios.get(process.env.REACT_APP_API_ENDPOINT)
        .then((resp) => {
            console.log(resp)
        })
        .catch((err) => {
            console.log(err)
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}

export const sortCountry = (data) =>{
    data.sort(function(a, b) {
        if (a.id === 14) {
            return 1; // Move object with id 14 to the end (ALL country)
        }
        if (b.id === 14) {
            return -1; // Move object with id 14 to the end
        }
    
        var countryNameA = a.country_name.toUpperCase(); // ignore case
        var countryNameB = b.country_name.toUpperCase(); // ignore case
    
        if (countryNameA < countryNameB) {
            return -1;
        }
        if (countryNameA > countryNameB) {
            return 1;
        }
        return 0;
    });

    return data
}

export const lang = (language) => {
    return dispatch => {
        fetch(`https://cdngarenanow-a.akamaihd.net/webid/Localize/translate_ff_distributors/${language}.json`)
        .then((resp) => {
            return resp.json()
        })
        .then(data => {
            dispatch(localize(data));

            fetch('https://webid.cdn.garenanow.com/json/global_ff_distributor_country_v2.json')
            .then((resp) => {
                return resp.json()
            })
            .then(data => {
                let country = data.data;
                dispatch(country_list(country))
            })
        })
    }
}