import React, { Component } from 'react';
import Box from '../../partial/Box';
import { withRouter } from "react-router-dom";

class Home extends Component {
    componentDidMount() {
        this.props.actionsMain.lang(this.props.match.params.lang);
    }

    componentDidUpdate() {
        if (this.props.match.params.lang !== this.props.main.localize.region) {
            this.props.actionsMain.lang(this.props.match.params.lang);
        }
    }
    render() {
        const { localize } = this.props.main
        return (
            <div className="container ff--distributor">
                <div className="ff--distributor_header">
                    <p>{localize.title}</p>
                </div>
                <Box {...this.props}/>
                <div className="ff--distributor_footer">
                    <img src="https://cdngarenanow-a.akamaihd.net/webid/FF/distributors/garena.png" alt="" />
                </div>
            </div>
        );
    }
}

export default withRouter(Home);