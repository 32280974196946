import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';

class Navbar extends Component {
    state = {
        links : [
            {
                route: '/',
                text: 'Home'
            },
            {
                route: '/test',
                text: 'Not Found'
            }
        ]
    }

    renderNavbar = () => {
        let list;
        list = this.state.links.map((data, index) =>
            <li key={index}>
                <Link to={data.route}>{data.text}</Link>
            </li>
        )
        return list;
    }

    render() {
        const { localize } = this.props.main
        return (
            <div className="navbar">
                <div className="ff-logo">
                    <img src="https://cdngarenanow-a.akamaihd.net/webid/FF/distributors/new_logo_ff.png" alt=""/>
                </div>
                
                <div className="lang-toggle">
                    <p>{localize.language_text}</p>
                    <NavLink to="/ar">العربية</NavLink>|<NavLink to="/eng">English</NavLink>
                </div>
            </div>
        );
    }
}

export default Navbar;