const main_state = {
	login: false,
	loader: false,
	localize: false,
	country: false
}

const main = (state = main_state, action) => {
	switch (action.type) {
		case "GET_LOGIN":
			return { ...state, login: action.data };
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data }
		case "LOCALIZE":
			return {
				...state,
				localize: action.data
			}
		case "COUNTRY_LIST":
			return {
				...state,
				country: action.data
			}
		default:
			return state;
	}
};

export default main;