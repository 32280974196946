import React, { Component } from 'react'

export default class Box extends Component {
    listNation = () => {
        let data = this.props.main.country
        let list = data && data.map((data, index) =>
            <div key={index} className="container-country">
                <div className="country">
                    <div className="flag">
                        <img src={data.country_image} alt="" />
                    </div>
                    <div className="name">
                        <span>{this.props.match.params.lang === 'eng' ? data.country_name : data.country_name_arabic}</span>
                    </div>
                </div>
                <div className="distributor">
                    {data.sponsor.map((data2, index2) =>
                        <div key={index2} className="brand">
                            <div className="logo">
                                <img src={data2.sponsor_image} alt="" />
                            </div>
                            <div className="name">
                                <span>{this.props.match.params.lang === 'eng' ? data2.sponsor_name : data2.sponsor_name_arabic}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
        return list
    }
    render() {
        return (
            <div className="ff--distributor_box">
                <div className="content">
                    {this.listNation()}
                </div>
            </div>
        )
    }
}
