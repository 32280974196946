import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

// Component
import ViewportRestrict from 'component/viewport';
import Loader from 'component/loader';
// import NavHamburger from 'component/navbar';

// Pages
import Home from 'pages/Home';
import ErrorBoundary from 'ErrorBoundary';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import Navbar from '../partial/Navbar';

const NoMatch = () => (
	<div>404 Not Found</div>
);

class Main extends Component {
	render() {
		return (
			<Router basename={process.env.REACT_APP_BASENAME}>
				<div id="Main" className="main-panel">
					<ViewportRestrict display={false} type="landscape" />
					<Loader />
					<Navbar {...this.props}/>
					<div className="content-container">
						<ErrorBoundary>
							<Switch>
								<Route exact path="/">
									<Redirect to="/ar" />
								</Route>
								<Route path="/:lang(ar|eng)" render={() => <Home {...this.props} />} />
								<Route component={NoMatch} />
							</Switch>
						</ErrorBoundary>
					</div>
				</div>
			</Router>
		);
	}
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);